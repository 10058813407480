// extracted by mini-css-extract-plugin
export const container = "priceSection-module--container--1did5";
export const priceCardContainer = "priceSection-module--priceCardContainer--3BCwq";
export const priceCard = "priceSection-module--priceCard--3i4LL";
export const carSizeContainer = "priceSection-module--carSizeContainer--3REEs";
export const carSizeHeading = "priceSection-module--carSizeHeading--2uszD";
export const openTableBtn = "priceSection-module--openTableBtn--3Hd68";
export const closeTableBtn = "priceSection-module--closeTableBtn--9vBUW";
export const tableContainer = "priceSection-module--tableContainer--2X1Ws";
export const table = "priceSection-module--table--2B7YD";
export const headerExamples = "priceSection-module--headerExamples--1wG9v";
export const tablePackageDescription = "priceSection-module--tablePackageDescription--3BEE8";
export const tablePriceContainer = "priceSection-module--tablePriceContainer--2Mgm4";
export const tablePrice = "priceSection-module--tablePrice--3ygH4";
export const tablePriceOnRequest = "priceSection-module--tablePriceOnRequest--1GrYG";
export const tablePriceFrom = "priceSection-module--tablePriceFrom--2wu9Y";
export const tablePriceAsterix = "priceSection-module--tablePriceAsterix--3gmuo";
export const tablePriceVAT = "priceSection-module--tablePriceVAT--3Uryo";
export const priceBasedOn = "priceSection-module--priceBasedOn--jAJjr";
export const notes = "priceSection-module--notes--CB-uS";