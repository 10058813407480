// extracted by mini-css-extract-plugin
export const container = "servicePage-module--container--2B8oX";
export const containerGrid = "servicePage-module--containerGrid--35j8O";
export const aboutContainer = "servicePage-module--aboutContainer--ieAp3";
export const serviceImg = "servicePage-module--serviceImg--1pzq-";
export const aboutText = "servicePage-module--aboutText--FZtYk";
export const featureText = "servicePage-module--featureText--DdpPo";
export const featureTitle = "servicePage-module--featureTitle--IVy97";
export const openFeatureBtn = "servicePage-module--openFeatureBtn--3x3z0";
export const priceCards = "servicePage-module--priceCards--2zkTQ";
export const carIcon = "servicePage-module--carIcon--1STRR";
export const projectsGrid = "servicePage-module--projectsGrid--3I6bJ";
export const noProjectMessage = "servicePage-module--noProjectMessage--3D6qI";
export const tintGrid = "servicePage-module--tintGrid--1BYcU";
export const tintTypeCard = "servicePage-module--tintTypeCard--1W9Id";
export const tintTypeImg = "servicePage-module--tintTypeImg--3wSMy";
export const beforeAfterGrid = "servicePage-module--beforeAfterGrid--1KsTg";
export const beforeAfterCard = "servicePage-module--beforeAfterCard--1Cv1C";
export const beforeAfterImg = "servicePage-module--beforeAfterImg--1tZLw";
export const beforeAfterTitle = "servicePage-module--beforeAfterTitle--1Dpry";
export const faqContainer = "servicePage-module--faqContainer--30PKO";
export const faqQuestion = "servicePage-module--faqQuestion--2YD0J";
export const openFaqBtn = "servicePage-module--openFaqBtn--2YdhI";
export const faqAnswer = "servicePage-module--faqAnswer--ha1jW";
export const carLogoGrid = "servicePage-module--carLogoGrid--35LF0";
export const carLogo = "servicePage-module--carLogo--ifGn5";
export const carLogoCard = "servicePage-module--carLogoCard--3nWlz";
export const mobileDeviceImgGrid = "servicePage-module--mobileDeviceImgGrid--UPDYz";
export const mobileDevice = "servicePage-module--mobileDevice--1Wd3R";
export const mobileDeviceImg = "servicePage-module--mobileDeviceImg--2aFpu";
export const videoGrid = "servicePage-module--videoGrid--3FvXi";